<template>
  <v-row>
    <v-col
      ><v-select
        :label="$t('model.name.institution')"
        :placeholder="$t('model.name.institution')"
        class="white"
        :items="data"
        item-text="name"
        v-model="id"
        item-value="id"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect"],
  data: () => ({
    item: null,
    id:null,
    data:[],
    selectedSchool: {},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    id: function (val) {
      this.selectedSchool = this.$_.find(this.data, function (obj) {
        return obj.id == val;
      });
      this.callbackSelect(val);
    },
  },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = parseInt(this.$route.params.grandParentId)
    };
  },
  mounted(){
this.fetch()
  },
  methods:{
    fetch() {
      this.api.url =`${this.$api.servers.sso}/api/v1/en/data/institution`
      this.$api.fetch(this.api);
    }
  }
};
</script>

<style>
</style>